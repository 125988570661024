import { useCallback, useEffect, useState } from "react";
import { Sketch } from "./sketch";
import { useDraggable } from "./draggable";

export function App() {
    const [isMinimized, setIsMinimized] = useState(false);
    const [sliderValue, setSliderValue] = useState(20);
    const [isPaused, setIsPaused] = useState(false);
    const disabled = false;
    const { ref, styles } = useDraggable({ disabled });

    function handleToggleMinimize(event) {
        let is_minimized = isMinimized;
        setIsMinimized(!is_minimized);
        console.log("handleToggleMinimize: " + isMinimized);
    }

    return (
        <>
            <div className="panel control-panel" style={styles}>
                {/* Panel handlebar with minimize button */}
                <div className="panel handle" ref={ref}>
                    <button onClick={handleToggleMinimize}>-</button>
                </div>
                {isMinimized ? (
                    <></>
                ) : (
                    <div>
                        {/* Image import button */}
                        <div className="control-panel-inner">
                            <div className="button-grid">
                                <div className="button-grid-item">
                                    <button
                                        id="goto_digitizer"
                                        type="button"
                                        onClick={() =>
                                            (window.location.href =
                                                "https://msx-digitizer.blitzblit.com")
                                        }
                                    >
                                        Start MSX digitizer
                                    </button>
                                    <button
                                        id="goto_blitzblit"
                                        type="button"
                                        onClick={() =>
                                            (window.location.href =
                                                "https://www.blitzblit.com")
                                        }
                                    >
                                        Go to www.blitzblit.com
                                    </button>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                )}
            </div>
            <ThreeSketch
                paused={isPaused}
                sliderValue={sliderValue}
                className="sketch"
            />
        </>
    );
}

function ThreeSketch({ paused, sliderValue, className }) {
    const [sketch, setSketch] = useState();

    // Scene constructor
    const nodeRef = useCallback((node) => {
        if (node !== null) {
            setSketch(new Sketch({ dom: node }));
        }
    }, []);

    // Scene destructor
    useEffect(() => {
        if (!sketch) {
            return;
        }
        return () => {
            sketch.destroy();
        };
    }, [sketch]);

    // Passes state from React app to Sketch object.
    useEffect(() => {
        if (!sketch) {
            return;
        }
        if (paused) {
            sketch.stop();
        } else {
            sketch.play();
        }
    }, [paused, sketch]);

    return <div ref={nodeRef} className={className} />;
}
